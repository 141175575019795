import { roseDayjs, IRoseAGGridColumn } from '@rose/common-ui';
import { ICollectionStatistics } from '@rose/types';

export function settingsImportColumnsDefs(): IRoseAGGridColumn<ICollectionStatistics>[] {
  return [
    {
      headerName: 'Start-Zeitpunkt',
      valueFormatter: params =>
        params.data?.importinfo.started ? roseDayjs(params.data.importinfo.started).format('DD.MM.YYYY HH:mm:ss') : '',
      resizable: true,
      width: 160,
    },
    {
      headerName: 'End-Zeitpunkt',
      valueFormatter: params =>
        params.data?.importinfo.ended ? roseDayjs(params.data.importinfo.ended).format('DD.MM.YYYY HH:mm:ss') : '',
      resizable: true,
      width: 160,
    },
    {
      headerName: 'Dauer',
      field: 'duration',
      valueFormatter: params =>
        params.data?.duration
          ? roseDayjs
              .duration(params.data.duration, 'seconds')
              .format(params.data.duration > 3600 ? 'H[h] m[m] s[s]' : 'm[m] s[s]')
          : '',
      resizable: true,
      width: 100,
    },
    {
      headerName: 'ID / Aktionen',
      field: 'id',
      cellRenderer: 'ImportIdCell',
      resizable: false,
      width: 250,
    },
    {
      headerName: 'Update',
      field: 'incremental',
      cellRenderer: 'CheckAndCrossCell',
      resizable: true,
      cellStyle: { 'justify-content': 'center' },
      width: 80,
    },
    {
      headerName: 'Patienten',
      field: 'patcount',
      resizable: true,
      width: 100,
    },
    {
      headerName: 'Patienten-IDs & Fehler',
      minWidth: 300,
      flex: 1,
      cellRenderer: 'ImportPatIdsCell',
      resizable: true,
    },
  ];
}
